import React from "react";
import { useSpring, animated, config } from "react-spring";
import { Link } from "gatsby";

// eslint-disable-next-line react/prop-types
const SideNav = ({ isOpen }) => {
  const { x } = useSpring({
    x: isOpen ? 0 : 100,
    config: config.slow,
  });
  return (
    <div
      className="fixed inset-0 z-20 flex"
      style={{
        pointerEvents: isOpen ? "all" : "none",
      }}
    >
      <animated.div
        style={{
          transform: x.interpolate((x) => `translate3d(${x * -1}%, 0, 0)`),
        }}
        className="w-2/5 h-full bg-transparent "
      />
      <animated.div
        style={{
          transform: x.interpolate((x) => `translate3d(${x}%, 0, 0)`),
        }}
        className="w-3/5 h-full bg-black"
      >
        <animated.div className="fixed inset-0 z-20 px-5 py-24">
          <nav className="grid gap-y-8">
            <Link
              to="/"
              className="flex items-center justify-end p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md "
            >
              <div className="text-lg font-medium leading-6 tracking-widest text-white">
                Home
              </div>

              <svg
                className="flex-shrink-0 w-6 h-6 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                ></path>
              </svg>
            </Link>
            <Link
              to="/services"
              className="flex items-center justify-end p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md "
            >
              <div className="text-lg font-medium leading-6 tracking-widest text-white">
                Services
              </div>
              <svg
                className="w-6 h-6 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </Link>
            {/* <Link
              to="/blog"
              className="flex items-center justify-end p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md "
            >
              <div className="text-lg font-medium leading-6 tracking-widest text-white">
                Blog
              </div>
              <svg
                className="w-6 h-6 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                />
              </svg>
            </Link> */}
            <Link
              to="/contact"
              className="flex items-center justify-end p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md "
            >
              <div className="text-lg font-medium leading-6 tracking-widest text-white">
                Contact
              </div>
              <svg
                className="w-6 h-6 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </Link>
          </nav>
        </animated.div>
        <div className="absolute bottom-0 w-full px-5 py-5 bg-green-600 ">
          <h3 className="font-bold tracking-wide text-white text-md">
            Contact Information
          </h3>
          <p className="pt-3 text-sm font-light text-white">(346) 217-5882</p>
          <p className="text-sm font-light text-white ">
            info@catalinaadvisors.com
          </p>
        </div>
      </animated.div>
    </div>
  );
};

export default SideNav;
